import { Link } from "react-router-dom";

import VideoBackground from "../components/VideoBackground";

import imgAndrew from "../img/andrew.webp";
import imgMartyn from "../img/martyn.webp";

import imgWCC from "../img/WCCLogo.webp";
import imgBushfireNT from "../img/bushfirent.webp";
import imgCOO from "../img/COOLogo.webp";
import imgCOL from "../img/COL_svg.svg";
import imgQYAC from "../img/QYAC.webp";
import imgGC from "../img/GC.webp";
import imgNoosa from "../img/Noosa.webp";
import imgBCC from "../img/BCC.webp";
import imgQPWS from "../img/QPWS.webp";
import imgTLTGreen from "../img/tlt_green.webp";
import imgNEBO from "../img/NEBO.webp";
import imgMGCA from "../img/MGCA.webp";







import video from "../video/fire4.mp4";
import videoM from "../video/fireM.mp4";

function Home() {
  const isMobile = window.innerWidth <= 1000;

  const selectedVideo = isMobile ? videoM : video;

  return (
    <>
      <VideoBackground video={selectedVideo}></VideoBackground>

      <div className="page-container">
        <div className="logo-container">
          <svg
            className="logo-old desk-hide"
            width="100%"
            height="100%"
            viewBox="0 0 431 302"
            version="1.1"
            xmlSpace="preserve"
            style={{
              fillRule: "evenodd",
              clipRule: "evenodd",
              strokeLinejoin: "round",
              strokeMiterlimit: 2,
            }}
          >
            <g transform="matrix(1,0,0,1,-43.3586,-77.4667)">
              <path
                d="M243.25,220.748c-6.141,-5.506 -8.574,-11.984 -5.965,-19.56c2.526,-7.338 8.899,-9.941 15.915,-11.134c8.154,-1.386 15.889,-0.043 23.145,4.633c1.099,-1.604 1.972,-2.876 3.57,-5.207l-0,22.884c-0.479,0.081 -0.958,0.161 -1.437,0.241c-0.412,-1.611 -0.544,-3.367 -1.286,-4.807c-2.247,-4.358 -4.105,-9.145 -7.279,-12.734c-3.889,-4.396 -11.449,-3.903 -14.831,-0.051c-3.273,3.729 -2.609,9.605 2.086,13.767c3.719,3.298 7.972,5.988 11.901,9.06c3.4,2.658 7.05,5.132 9.938,8.284c8.501,9.274 5.517,22.789 -6.109,27.489c-9.307,3.762 -18.839,3.609 -28.245,-0.04c-1.519,-0.589 -2.995,-1.291 -4.711,-2.036c-1.007,1.515 -1.853,2.788 -3.267,4.916l-0,-27.044c0.453,-0.046 0.907,-0.093 1.36,-0.139c0.285,1.492 0.449,3.018 0.876,4.468c1.262,4.277 2.071,8.809 4.079,12.717c3.203,6.23 9.514,8.36 16.22,6.396c6.757,-1.98 8.792,-10.102 3.679,-16.268c-2.508,-3.024 -5.838,-5.402 -8.95,-7.878c-3.382,-2.691 -6.942,-5.157 -10.689,-7.957Z"
                style={{ fill: "rgb(255,132,10)", fillRule: "nonzero" }}
              />
              <path
                d="M363.711,210.951c0.022,0.496 -0.101,1.057 0.087,1.479c5.932,13.328 0.776,21.792 -14.056,23.21c-2.86,0.274 -7.506,-1.168 -7.293,3.617c0.22,4.917 4.881,2.47 7.624,2.864c4.593,0.659 9.308,0.575 13.854,1.429c4.63,0.869 8.354,3.349 9.504,8.425c1.226,5.412 -0.146,10.639 -4.629,13.305c-5.119,3.045 -11.244,4.399 -17.334,6.206c-0.41,-0.649 -0.418,-1.016 -0.426,-1.382c1.671,-0.716 4.019,-0.98 4.865,-2.247c1.523,-2.282 3.142,-5.304 2.8,-7.749c-0.234,-1.675 -3.545,-3.591 -5.792,-4.153c-3.157,-0.789 -6.61,-0.318 -9.922,-0.573c-4.678,-0.359 -9.17,-1.469 -10.482,-6.761c-1.286,-5.183 1.83,-8.447 5.832,-11.057c0.805,-0.526 1.586,-1.089 2.813,-1.935c-2.37,-0.923 -4.329,-1.565 -6.184,-2.43c-5.269,-2.456 -7.559,-6.785 -7.436,-12.456c0.12,-5.5 2.577,-9.627 7.748,-11.73c8.815,-3.583 17.522,-3.102 26.55,1.377c0.976,0.334 1.426,0.448 1.877,0.561m-12.911,8.679c-0.339,-2.798 -0.477,-5.643 -1.118,-8.37c-0.251,-1.065 -1.633,-1.865 -2.499,-2.785c-1,0.963 -2.829,1.895 -2.865,2.893c-0.241,6.584 -0.199,13.185 0.039,19.77c0.034,0.938 1.627,1.82 2.499,2.727c0.992,-0.973 2.637,-1.82 2.855,-2.945c0.661,-3.4 0.768,-6.907 1.089,-11.29Z"
                style={{ fill: "rgb(255,132,8)", fillRule: "nonzero" }}
              />
              <path
                d="M70.995,192.942c-0.983,1.569 -2.755,3.109 -2.816,4.713c-0.296,7.794 -0.133,15.606 -0.133,23.821c8.549,0.518 10.681,-6.179 14.714,-11.381l0,25.821c-8.26,-12.132 -8.26,-12.132 -14.663,-11.682c-0,7.432 -0.064,14.895 0.027,22.356c0.045,3.718 1.993,5.828 5.832,6.083c1.332,0.088 2.646,0.432 3.807,1.798l-34.124,-0c7.558,-1.899 5.976,-7.909 5.991,-13.107c0.041,-13.476 0.116,-26.954 -0.078,-40.427c-0.036,-2.471 -1.276,-4.925 -2.257,-7.972c-0.294,-1.009 -0.29,-1.431 -0.287,-1.853c7.683,0.003 15.366,0.007 23.511,0.364c0.467,0.724 0.472,1.095 0.476,1.466Z"
                style={{ fill: "rgb(255,130,5)", fillRule: "nonzero" }}
              />
              <path
                d="M406.142,213.524c-0.44,2.08 -1.223,4.154 -1.258,6.241c-0.144,8.491 -0.289,16.995 0.064,25.474c0.127,3.06 1.648,6.062 2.583,9.259l-25.444,0c7.792,-2.798 6.217,-8.278 6.235,-13.117c0.059,-14.988 0.015,-29.976 0.029,-44.964c0.004,-4.192 0.188,-8.439 -4.798,-10.875c6.841,-0.31 13.682,-0.619 21.652,-0.98c0,9.014 0,18.078 0.435,27.688c0.458,0.789 0.48,1.031 0.502,1.274Z"
                style={{ fill: "rgb(255,134,13)", fillRule: "nonzero" }}
              />
              <path
                d="M208.536,255.981c-15.51,0.562 -22.588,-5.621 -24.09,-20.616c-1.485,-14.822 4.596,-25.084 16.58,-27.985c14.675,-3.551 24.11,4.063 23.394,19.632c-3.71,0 -7.468,0 -11.226,0c-3.789,0 -7.577,0 -11.852,0c0.073,6.885 -0.743,13.732 5.455,17.643c6.219,3.924 11.688,0.724 16.323,-4.677c0.244,7.264 -5.651,13.592 -14.584,16.003m0.525,-30.802c0.815,-0.573 2.369,-1.207 2.328,-1.711c-0.317,-3.922 -0.752,-7.861 -1.592,-11.697c-0.24,-1.095 -1.933,-2.595 -2.962,-2.597c-1.113,-0.001 -2.907,1.335 -3.225,2.429c-1.239,4.278 -2.011,8.691 -3.072,13.58c3.384,-0 5.512,-0 8.523,-0.004Z"
                style={{ fill: "rgb(255,132,9)", fillRule: "nonzero" }}
              />
              <path
                d="M465.22,234.984c0,3.825 -0.053,7.154 0.012,10.481c0.105,5.312 1.49,6.066 6.438,3.618c0.522,-0.258 1.086,-0.433 1.889,-0.748c-1.859,5.813 -6.875,8.387 -14.357,7.713c-6.076,-0.547 -9.623,-3.619 -9.907,-9.802c-0.427,-9.3 -0.242,-18.628 -0.307,-27.944c-0.019,-2.631 -0.003,-5.263 -0.003,-8.103c-2.447,-0.263 -4.497,-0.484 -6.547,-0.705c-0.046,-0.352 -0.092,-0.705 -0.139,-1.058c2.05,-0.267 4.099,-0.535 6.625,-0.864l0,-9.63c5.616,-1.254 10.731,-2.396 16.359,-3.652l-0,13.406c3.175,0.2 5.83,0.367 8.486,0.535c0.011,0.433 0.023,0.867 0.034,1.3c-2.653,0.181 -5.307,0.361 -8.583,0.583c-0,8.168 -0,16.27 -0,24.87Z"
                style={{ fill: "rgb(255,130,6)", fillRule: "nonzero" }}
              />
              <path
                d="M159.135,213.385c-0.434,3.757 -1.115,7.506 -1.248,11.273c-0.211,5.983 -0.06,11.979 -0.055,17.969c0.008,8.469 0.011,8.469 7.417,11.699l-29.465,0c7.266,-2.754 5.474,-8.427 5.544,-13.39c0.108,-7.652 0.347,-15.326 -0.115,-22.952c-0.191,-3.146 -2.052,-6.191 -3.279,-9.633c5.38,-0.265 12.141,-0.598 19.271,-0.949c0.317,1.199 0.679,2.569 1.437,4.378c0.429,0.827 0.461,1.216 0.493,1.605Z"
                style={{ fill: "rgb(255,134,13)", fillRule: "nonzero" }}
              />
              <path
                d="M406.51,213.325c-0.39,-0.044 -0.412,-0.286 -0.462,-0.893c4.64,-5.639 14.484,-7.497 20.507,-4.255c4.477,2.411 5.246,6.686 5.323,11.107c0.151,8.659 0.061,17.323 0.086,25.985c0.011,3.744 -0.427,7.758 4.805,9.142l-25.508,0c5.444,-3.052 4.337,-8.002 4.359,-12.593c0.038,-7.829 0.073,-15.659 -0.012,-23.487c-0.075,-6.887 -2.45,-8.255 -9.098,-5.006Z"
                style={{ fill: "rgb(255,131,7)", fillRule: "nonzero" }}
              />
              <path
                d="M310.903,254.767c-6.071,-0.008 -11.657,-0.008 -17.242,-0.008c5.829,-2.543 4.564,-7.548 4.595,-11.976c0.057,-7.992 -0.016,-15.985 0.034,-23.977c0.025,-4.096 -0.02,-8.085 -4.806,-10.398c6.811,-0.337 13.622,-0.675 21.286,-1.054c-0,1.713 -0,3.443 -0,5.173c-0,10.49 0.039,20.981 -0.019,31.471c-0.024,4.164 -0.419,8.399 4.751,10.777c-2.704,-0 -5.409,-0 -8.599,-0.008Z"
                style={{ fill: "rgb(255,134,14)", fillRule: "nonzero" }}
              />
              <path
                d="M107.073,243.809c0.001,-8.797 -0.029,-17.117 0.016,-25.437c0.021,-3.977 -0.274,-7.719 -4.834,-9.96c6.785,-0.323 13.569,-0.647 21.202,-1.011c-0,2.304 -0,4.7 -0,7.095c0,9.985 0.065,19.969 -0.029,29.953c-0.039,4.089 -0.02,7.979 4.767,10.037l-25.731,0c4.327,-2.195 5.153,-5.885 4.609,-10.677Z"
                style={{ fill: "rgb(255,131,7)", fillRule: "nonzero" }}
              />
              <path
                d="M159.544,213.145c-0.441,-0.149 -0.473,-0.538 -0.488,-1.218c3.692,-4.674 9.796,-6.533 14.106,-4.581c3.38,1.53 5.352,6.327 4.393,10.683c-1.098,4.986 -5.387,7.657 -10.512,6.546c-4.617,-1.001 -6.238,-3.899 -5.347,-9.365c0.199,-1.225 1.945,-3.908 -2.152,-2.065m3.321,-1.593c-0.217,-0.079 -0.434,-0.158 -0.651,-0.238c0.068,0.224 0.135,0.448 0.651,0.238Z"
                style={{ fill: "rgb(255,135,15)", fillRule: "nonzero" }}
              />
              <path
                d="M71.416,192.995c-0.425,-0.424 -0.43,-0.795 -0.435,-1.444c7.415,-0.279 14.831,-0.279 22.268,-0.279l0,21.133c-0.378,0.235 -0.757,0.47 -1.136,0.705c-3.844,-9.842 -6.394,-21.085 -20.697,-20.115Z"
                style={{ fill: "rgb(255,137,20)", fillRule: "nonzero" }}
              />
              <path
                d="M254.868,341.275c0.849,1.083 1.651,2.187 1.655,3.294c0.031,10.113 -0.005,20.229 -0.237,30.339c-0.028,1.241 -1.318,2.453 -2.023,3.678c-0.717,-1.192 -2.041,-2.376 -2.055,-3.577c-0.125,-10.112 -0.079,-20.228 0.13,-30.339c0.024,-1.138 1.377,-2.249 2.53,-3.395Z"
                style={{ fill: "rgb(255,101,101)", fillRule: "nonzero" }}
              />
              <path
                d="M207.299,112.599c1.449,3.409 2.748,6.466 4.047,9.523c-0.218,0.456 -0.435,0.912 -0.653,1.367c-1.289,-0.531 -3.331,-0.719 -3.753,-1.646c-4.826,-10.585 -9.405,-21.283 -14.024,-31.891c2.348,-2.45 3.737,-2.048 4.911,0.743c3.036,7.215 6.206,14.372 9.472,21.904Z"
                style={{ fill: "rgb(255,100,100)", fillRule: "nonzero" }}
              />
              <path
                d="M310.256,359.857c-3.84,-8.836 -7.525,-17.325 -11.324,-26.076c1.281,-0.54 2.308,-0.973 3.783,-1.595c4.917,11.268 9.799,22.453 14.665,33.603c-3.286,2.497 -3.31,2.509 -4.777,-0.58c-0.781,-1.645 -1.464,-3.335 -2.347,-5.352Z"
                style={{ fill: "rgb(255,100,100)", fillRule: "nonzero" }}
              />
              <path
                d="M193.352,360.259c4.393,-9.729 8.626,-19.114 13.019,-28.853c1.266,0.464 2.392,0.877 3.855,1.414c-4.886,10.882 -9.572,21.504 -14.502,32.011c-0.489,1.041 -2.468,1.384 -3.756,2.051c-0.278,-0.439 -0.557,-0.878 -0.835,-1.318c0.686,-1.653 1.372,-3.306 2.219,-5.305Z"
                style={{ fill: "rgb(255,100,100)", fillRule: "nonzero" }}
              />
              <path
                d="M311.306,108.429c-2.064,4.588 -3.809,8.919 -5.988,13.018c-0.628,1.181 -2.508,1.697 -3.814,2.518c-0.375,-0.47 -0.751,-0.94 -1.127,-1.41c4.782,-10.564 9.522,-21.148 14.427,-31.655c0.374,-0.801 1.921,-1.054 2.923,-1.562c0.217,1.091 0.923,2.384 0.571,3.241c-2.146,5.223 -4.532,10.347 -6.992,15.85Z"
                style={{ fill: "rgb(255,98,98)", fillRule: "nonzero" }}
              />
              <path
                d="M347.42,312.548c7.445,7.111 14.635,13.976 21.824,20.841c-0.074,0.486 -0.147,0.972 -0.221,1.459c-1.353,-0 -3.244,0.585 -3.977,-0.089c-8.773,-8.075 -17.375,-16.336 -26.287,-24.801c4.705,-4.233 5.885,1.242 8.661,2.59Z"
                style={{ fill: "rgb(255,98,98)", fillRule: "nonzero" }}
              />
              <path
                d="M363.561,130.546c-6.943,6.44 -13.629,12.635 -20.619,19.111c-0.943,-0.811 -1.843,-1.584 -2.808,-2.415c0.412,-0.611 0.615,-1.078 0.956,-1.396c8.145,-7.558 16.334,-15.071 24.441,-22.67c1.88,-1.761 3.418,-2.795 4.971,0.752c-2.162,2.061 -4.423,4.217 -6.941,6.618Z"
                style={{ fill: "rgb(255,100,100)", fillRule: "nonzero" }}
              />
              <path
                d="M150.956,132.041c-3.336,-3.47 -6.415,-6.697 -10.664,-11.15c2.437,0.148 4.283,-0.313 5.052,0.395c8.805,8.104 17.447,16.387 26.113,24.603c-1.235,2.848 -2.408,3.453 -4.685,1.185c-5.068,-5.048 -10.359,-9.874 -15.816,-15.033Z"
                style={{ fill: "rgb(255,101,101)", fillRule: "nonzero" }}
              />
              <path
                d="M160.946,317.939c-5.645,5.197 -10.96,10.233 -16.477,15.037c-0.948,0.825 -2.757,0.662 -4.169,0.955c-0.228,-0.593 -0.457,-1.186 -0.686,-1.779c8.631,-7.972 17.207,-16.005 25.97,-23.829c0.968,-0.865 3,-0.539 4.539,-0.766c0.158,0.445 0.316,0.89 0.474,1.334c-3.13,2.937 -6.26,5.873 -9.651,9.048Z"
                style={{ fill: "rgb(255,98,98)", fillRule: "nonzero" }}
              />
              <path
                d="M253.884,124.102c0.753,-1.992 1.504,-3.525 2.256,-5.057c0.605,1.507 1.71,3.005 1.736,4.522c0.161,9.464 0.081,18.931 0.081,28.312c-3.745,1.141 -4.487,-0.375 -4.409,-3.332c0.213,-7.992 0.236,-15.989 0.336,-24.445Z"
                style={{ fill: "rgb(255,98,98)", fillRule: "nonzero" }}
              />
              <path
                d="M254.865,337.533c-1.121,-1.16 -2.478,-2.409 -2.502,-3.685c-0.154,-7.968 -0.076,-15.944 0.126,-23.912c0.023,-0.924 1.284,-1.818 1.971,-2.726c0.785,0.809 2.24,1.609 2.251,2.428c0.113,8.633 0.026,17.271 -0.117,25.905c-0.011,0.637 -0.88,1.26 -1.729,1.99Z"
                style={{ fill: "rgb(255,110,110)", fillRule: "nonzero" }}
              />
              <path
                d="M258.132,81.338c-0.04,10.397 -0.124,20.338 -0.086,30.278c0.01,2.682 -0.569,4.213 -3.954,2.911c0,-11.479 -0.036,-23.04 0.076,-34.6c0.008,-0.825 1.095,-1.64 1.68,-2.46c0.762,1.138 1.524,2.276 2.284,3.871Z"
                style={{ fill: "rgb(255,98,98)", fillRule: "nonzero" }}
              />
              <path
                d="M221.829,146.063c1.227,2.909 2.303,5.467 3.379,8.024c-0.364,0.398 -0.727,0.795 -1.091,1.193c-1.339,-0.908 -3.321,-1.514 -3.912,-2.773c-3.382,-7.198 -6.509,-14.521 -9.506,-21.89c-0.397,-0.977 0.44,-2.456 0.705,-3.702c1.038,0.797 2.537,1.375 3.036,2.427c2.555,5.389 4.852,10.901 7.389,16.721Z"
                style={{ fill: "rgb(255,101,101)", fillRule: "nonzero" }}
              />
              <path
                d="M295.127,144.27c-1.305,2.929 -2.236,5.648 -3.711,8.031c-0.824,1.331 -2.55,2.103 -3.87,3.128c-0.459,-0.402 -0.917,-0.803 -1.376,-1.205c3.913,-8.686 7.782,-17.393 11.829,-26.016c0.293,-0.623 1.886,-0.635 2.875,-0.931c0.188,1.002 0.818,2.187 0.492,2.976c-1.906,4.607 -4.037,9.121 -6.239,14.017Z"
                style={{ fill: "rgb(255,101,101)", fillRule: "nonzero" }}
              />
              <path
                d="M343.088,271.825c-3.894,-0.614 -7.984,-0.699 -11.613,-2.034c-2.262,-0.833 -5.368,-3.593 -5.321,-5.417c0.059,-2.295 2.657,-4.855 4.724,-6.652c1.371,-1.192 3.732,-1.247 6.133,-1.952c-3.25,10.025 -2.584,11.628 5.617,14.692c0.403,0.8 0.432,1.081 0.46,1.363Z"
                style={{ fill: "rgb(255,135,15)", fillRule: "nonzero" }}
              />
              <path
                d="M363.995,210.761c-0.735,0.077 -1.185,-0.037 -1.777,-0.343c2.659,-3.791 6.399,-5.815 10.746,-4.34c1.803,0.611 3.732,3.403 3.941,5.379c0.177,1.68 -1.668,4.238 -3.321,5.203c-1.022,0.596 -4.069,-0.577 -4.926,-1.831c-1.064,-1.556 -0.804,-4.017 -1.187,-6.561c-1.011,0.729 -2.102,1.516 -3.476,2.493Z"
                style={{ fill: "rgb(255,135,15)", fillRule: "nonzero" }}
              />
              <path
                d="M118.107,191.979c2.621,2.111 4.781,4.865 1.984,7.731c-1.595,1.635 -4.855,2.448 -7.276,2.287c-3.704,-0.248 -5.824,-2.904 -5.308,-6.848c0.187,-1.432 1.045,-2.777 2.288,-4.081c2.726,2.144 4.762,4.208 7.233,6.714c0.541,-2.909 0.81,-4.356 1.079,-5.803Z"
                style={{ fill: "rgb(247,208,134)", fillRule: "nonzero" }}
              />
              <path
                d="M292.977,320.142c-1.823,-4.276 -3.503,-8.199 -5.183,-12.123c0.417,-0.403 0.834,-0.807 1.251,-1.21c1.36,1.08 3.277,1.882 3.979,3.29c2.516,5.047 4.72,10.261 6.796,15.509c0.41,1.038 -0.269,2.507 -0.445,3.777c-1.095,-0.677 -2.609,-1.103 -3.198,-2.084c-1.271,-2.118 -2.066,-4.521 -3.2,-7.159Z"
                style={{ fill: "rgb(255,101,101)", fillRule: "nonzero" }}
              />
              <path
                d="M213.868,314.729c2.183,-2.888 1.313,-8.522 7.75,-7.135c-3.009,6.661 -5.792,13.016 -8.807,19.258c-0.398,0.824 -1.977,1.078 -3.01,1.596c-0.207,-1.128 -0.887,-2.436 -0.537,-3.351c1.303,-3.409 2.943,-6.689 4.604,-10.368Z"
                style={{ fill: "rgb(255,101,101)", fillRule: "nonzero" }}
              />
              <path
                d="M118.053,191.567c-0.215,1.859 -0.484,3.306 -1.025,6.215c-2.471,-2.506 -4.507,-4.57 -6.889,-6.734c1.132,-4.073 2.611,-8.046 4.09,-12.019c0.378,-0.051 0.757,-0.101 1.135,-0.152c0.878,4.093 1.757,8.186 2.689,12.69Z"
                style={{ fill: "rgb(242,87,25)", fillRule: "nonzero" }}
              />
              <path
                d="M310.887,199.875c-2.676,3.332 -6.264,3.557 -9.16,1.202c-1.589,-1.292 -1.646,-4.469 -1.781,-6.805c2.168,0.837 3.722,1.684 5.568,2.614c0.692,0.103 1.091,0.122 1.808,0.217c1.401,0.975 2.483,1.873 3.565,2.772Z"
                style={{ fill: "rgb(250,226,170)", fillRule: "nonzero" }}
              />
              <path
                d="M302.8,190.913c0.672,-2.814 1.344,-5.629 2.016,-8.444c0.484,-0.011 0.968,-0.022 1.452,-0.034c0.275,1.032 0.55,2.064 0.787,3.756c-1.123,2.77 -2.21,4.879 -3.481,6.737c-0.381,-0.839 -0.578,-1.427 -0.774,-2.015Z"
                style={{ fill: "rgb(242,87,25)", fillRule: "nonzero" }}
              />
              <path
                d="M303.759,193.178c1.086,-2.108 2.173,-4.217 3.35,-6.637c0.678,1.385 1.265,3.082 1.391,5.214c-0.806,2.048 -1.151,3.66 -1.496,5.272c-0.399,-0.019 -0.798,-0.038 -1.572,-0.457c-0.807,-1.397 -1.24,-2.395 -1.673,-3.392Z"
                style={{ fill: "rgb(255,135,15)", fillRule: "nonzero" }}
              />
              <path
                d="M307.322,197.103c0.027,-1.688 0.372,-3.3 1.063,-5.004c0.446,0.327 0.547,0.746 0.602,1.808c0.551,1.455 1.149,2.268 1.746,3.08c0.116,0.813 0.232,1.625 0.251,2.663c-1.179,-0.674 -2.261,-1.572 -3.662,-2.547Z"
                style={{ fill: "rgb(255,137,20)", fillRule: "nonzero" }}
              />
              <path
                d="M343.451,271.943c-0.391,-0.4 -0.42,-0.681 -0.474,-1.386c2.373,-0.471 4.772,-0.518 7.618,-0.509c0.455,0.422 0.463,0.789 0.48,1.431c-2.414,0.378 -4.837,0.481 -7.624,0.464Z"
                style={{ fill: "rgb(255,164,76)", fillRule: "nonzero" }}
              />
              <path
                d="M303.574,192.928c0.618,1.247 1.051,2.245 1.566,3.558c-1.472,-0.53 -3.026,-1.377 -4.895,-2.36c0.457,-1.068 1.228,-2 2.277,-3.072c0.474,0.447 0.671,1.035 1.052,1.874Z"
                style={{ fill: "rgb(248,200,96)", fillRule: "nonzero" }}
              />
              <path
                d="M311.006,196.904c-0.87,-0.729 -1.468,-1.542 -1.991,-2.671c0.804,0.652 1.535,1.62 1.991,2.671Z"
                style={{ fill: "rgb(247,208,134)", fillRule: "nonzero" }}
              />
              <path
                d="M46.642,191.02c0.363,0.514 0.359,0.936 0.355,1.675c-1.199,0.119 -2.396,-0.079 -3.594,-0.278c-0.015,-0.322 -0.03,-0.645 -0.044,-0.967c0.972,-0.174 1.944,-0.348 3.283,-0.43Z"
                style={{ fill: "rgb(255,137,20)", fillRule: "nonzero" }}
              />
              <path
                d="M162.641,211.769c-0.292,-0.007 -0.359,-0.231 -0.427,-0.455c0.217,0.08 0.434,0.159 0.427,0.455Z"
                style={{ fill: "rgb(255,255,254)", fillRule: "nonzero" }}
              />
            </g>
          </svg>
        </div>
        <div className="title-container">
          {/* <Shape></Shape> */}
          <svg
            className="logo-old mbl-hide"
            width="20%"
            height="100%"
            viewBox="0 0 431 302"
            version="1.1"
            xmlSpace="preserve"
            style={{
              fillRule: "evenodd",
              clipRule: "evenodd",
              strokeLinejoin: "round",
              strokeMiterlimit: 2,
            }}
          >
            <g transform="matrix(1,0,0,1,-43.3586,-77.4667)">
              <path
                d="M243.25,220.748c-6.141,-5.506 -8.574,-11.984 -5.965,-19.56c2.526,-7.338 8.899,-9.941 15.915,-11.134c8.154,-1.386 15.889,-0.043 23.145,4.633c1.099,-1.604 1.972,-2.876 3.57,-5.207l-0,22.884c-0.479,0.081 -0.958,0.161 -1.437,0.241c-0.412,-1.611 -0.544,-3.367 -1.286,-4.807c-2.247,-4.358 -4.105,-9.145 -7.279,-12.734c-3.889,-4.396 -11.449,-3.903 -14.831,-0.051c-3.273,3.729 -2.609,9.605 2.086,13.767c3.719,3.298 7.972,5.988 11.901,9.06c3.4,2.658 7.05,5.132 9.938,8.284c8.501,9.274 5.517,22.789 -6.109,27.489c-9.307,3.762 -18.839,3.609 -28.245,-0.04c-1.519,-0.589 -2.995,-1.291 -4.711,-2.036c-1.007,1.515 -1.853,2.788 -3.267,4.916l-0,-27.044c0.453,-0.046 0.907,-0.093 1.36,-0.139c0.285,1.492 0.449,3.018 0.876,4.468c1.262,4.277 2.071,8.809 4.079,12.717c3.203,6.23 9.514,8.36 16.22,6.396c6.757,-1.98 8.792,-10.102 3.679,-16.268c-2.508,-3.024 -5.838,-5.402 -8.95,-7.878c-3.382,-2.691 -6.942,-5.157 -10.689,-7.957Z"
                style={{ fill: "rgb(255,132,10)", fillRule: "nonzero" }}
              />
              <path
                d="M363.711,210.951c0.022,0.496 -0.101,1.057 0.087,1.479c5.932,13.328 0.776,21.792 -14.056,23.21c-2.86,0.274 -7.506,-1.168 -7.293,3.617c0.22,4.917 4.881,2.47 7.624,2.864c4.593,0.659 9.308,0.575 13.854,1.429c4.63,0.869 8.354,3.349 9.504,8.425c1.226,5.412 -0.146,10.639 -4.629,13.305c-5.119,3.045 -11.244,4.399 -17.334,6.206c-0.41,-0.649 -0.418,-1.016 -0.426,-1.382c1.671,-0.716 4.019,-0.98 4.865,-2.247c1.523,-2.282 3.142,-5.304 2.8,-7.749c-0.234,-1.675 -3.545,-3.591 -5.792,-4.153c-3.157,-0.789 -6.61,-0.318 -9.922,-0.573c-4.678,-0.359 -9.17,-1.469 -10.482,-6.761c-1.286,-5.183 1.83,-8.447 5.832,-11.057c0.805,-0.526 1.586,-1.089 2.813,-1.935c-2.37,-0.923 -4.329,-1.565 -6.184,-2.43c-5.269,-2.456 -7.559,-6.785 -7.436,-12.456c0.12,-5.5 2.577,-9.627 7.748,-11.73c8.815,-3.583 17.522,-3.102 26.55,1.377c0.976,0.334 1.426,0.448 1.877,0.561m-12.911,8.679c-0.339,-2.798 -0.477,-5.643 -1.118,-8.37c-0.251,-1.065 -1.633,-1.865 -2.499,-2.785c-1,0.963 -2.829,1.895 -2.865,2.893c-0.241,6.584 -0.199,13.185 0.039,19.77c0.034,0.938 1.627,1.82 2.499,2.727c0.992,-0.973 2.637,-1.82 2.855,-2.945c0.661,-3.4 0.768,-6.907 1.089,-11.29Z"
                style={{ fill: "rgb(255,132,8)", fillRule: "nonzero" }}
              />
              <path
                d="M70.995,192.942c-0.983,1.569 -2.755,3.109 -2.816,4.713c-0.296,7.794 -0.133,15.606 -0.133,23.821c8.549,0.518 10.681,-6.179 14.714,-11.381l0,25.821c-8.26,-12.132 -8.26,-12.132 -14.663,-11.682c-0,7.432 -0.064,14.895 0.027,22.356c0.045,3.718 1.993,5.828 5.832,6.083c1.332,0.088 2.646,0.432 3.807,1.798l-34.124,-0c7.558,-1.899 5.976,-7.909 5.991,-13.107c0.041,-13.476 0.116,-26.954 -0.078,-40.427c-0.036,-2.471 -1.276,-4.925 -2.257,-7.972c-0.294,-1.009 -0.29,-1.431 -0.287,-1.853c7.683,0.003 15.366,0.007 23.511,0.364c0.467,0.724 0.472,1.095 0.476,1.466Z"
                style={{ fill: "rgb(255,130,5)", fillRule: "nonzero" }}
              />
              <path
                d="M406.142,213.524c-0.44,2.08 -1.223,4.154 -1.258,6.241c-0.144,8.491 -0.289,16.995 0.064,25.474c0.127,3.06 1.648,6.062 2.583,9.259l-25.444,0c7.792,-2.798 6.217,-8.278 6.235,-13.117c0.059,-14.988 0.015,-29.976 0.029,-44.964c0.004,-4.192 0.188,-8.439 -4.798,-10.875c6.841,-0.31 13.682,-0.619 21.652,-0.98c0,9.014 0,18.078 0.435,27.688c0.458,0.789 0.48,1.031 0.502,1.274Z"
                style={{ fill: "rgb(255,134,13)", fillRule: "nonzero" }}
              />
              <path
                d="M208.536,255.981c-15.51,0.562 -22.588,-5.621 -24.09,-20.616c-1.485,-14.822 4.596,-25.084 16.58,-27.985c14.675,-3.551 24.11,4.063 23.394,19.632c-3.71,0 -7.468,0 -11.226,0c-3.789,0 -7.577,0 -11.852,0c0.073,6.885 -0.743,13.732 5.455,17.643c6.219,3.924 11.688,0.724 16.323,-4.677c0.244,7.264 -5.651,13.592 -14.584,16.003m0.525,-30.802c0.815,-0.573 2.369,-1.207 2.328,-1.711c-0.317,-3.922 -0.752,-7.861 -1.592,-11.697c-0.24,-1.095 -1.933,-2.595 -2.962,-2.597c-1.113,-0.001 -2.907,1.335 -3.225,2.429c-1.239,4.278 -2.011,8.691 -3.072,13.58c3.384,-0 5.512,-0 8.523,-0.004Z"
                style={{ fill: "rgb(255,132,9)", fillRule: "nonzero" }}
              />
              <path
                d="M465.22,234.984c0,3.825 -0.053,7.154 0.012,10.481c0.105,5.312 1.49,6.066 6.438,3.618c0.522,-0.258 1.086,-0.433 1.889,-0.748c-1.859,5.813 -6.875,8.387 -14.357,7.713c-6.076,-0.547 -9.623,-3.619 -9.907,-9.802c-0.427,-9.3 -0.242,-18.628 -0.307,-27.944c-0.019,-2.631 -0.003,-5.263 -0.003,-8.103c-2.447,-0.263 -4.497,-0.484 -6.547,-0.705c-0.046,-0.352 -0.092,-0.705 -0.139,-1.058c2.05,-0.267 4.099,-0.535 6.625,-0.864l0,-9.63c5.616,-1.254 10.731,-2.396 16.359,-3.652l-0,13.406c3.175,0.2 5.83,0.367 8.486,0.535c0.011,0.433 0.023,0.867 0.034,1.3c-2.653,0.181 -5.307,0.361 -8.583,0.583c-0,8.168 -0,16.27 -0,24.87Z"
                style={{ fill: "rgb(255,130,6)", fillRule: "nonzero" }}
              />
              <path
                d="M159.135,213.385c-0.434,3.757 -1.115,7.506 -1.248,11.273c-0.211,5.983 -0.06,11.979 -0.055,17.969c0.008,8.469 0.011,8.469 7.417,11.699l-29.465,0c7.266,-2.754 5.474,-8.427 5.544,-13.39c0.108,-7.652 0.347,-15.326 -0.115,-22.952c-0.191,-3.146 -2.052,-6.191 -3.279,-9.633c5.38,-0.265 12.141,-0.598 19.271,-0.949c0.317,1.199 0.679,2.569 1.437,4.378c0.429,0.827 0.461,1.216 0.493,1.605Z"
                style={{ fill: "rgb(255,134,13)", fillRule: "nonzero" }}
              />
              <path
                d="M406.51,213.325c-0.39,-0.044 -0.412,-0.286 -0.462,-0.893c4.64,-5.639 14.484,-7.497 20.507,-4.255c4.477,2.411 5.246,6.686 5.323,11.107c0.151,8.659 0.061,17.323 0.086,25.985c0.011,3.744 -0.427,7.758 4.805,9.142l-25.508,0c5.444,-3.052 4.337,-8.002 4.359,-12.593c0.038,-7.829 0.073,-15.659 -0.012,-23.487c-0.075,-6.887 -2.45,-8.255 -9.098,-5.006Z"
                style={{ fill: "rgb(255,131,7)", fillRule: "nonzero" }}
              />
              <path
                d="M310.903,254.767c-6.071,-0.008 -11.657,-0.008 -17.242,-0.008c5.829,-2.543 4.564,-7.548 4.595,-11.976c0.057,-7.992 -0.016,-15.985 0.034,-23.977c0.025,-4.096 -0.02,-8.085 -4.806,-10.398c6.811,-0.337 13.622,-0.675 21.286,-1.054c-0,1.713 -0,3.443 -0,5.173c-0,10.49 0.039,20.981 -0.019,31.471c-0.024,4.164 -0.419,8.399 4.751,10.777c-2.704,-0 -5.409,-0 -8.599,-0.008Z"
                style={{ fill: "rgb(255,134,14)", fillRule: "nonzero" }}
              />
              <path
                d="M107.073,243.809c0.001,-8.797 -0.029,-17.117 0.016,-25.437c0.021,-3.977 -0.274,-7.719 -4.834,-9.96c6.785,-0.323 13.569,-0.647 21.202,-1.011c-0,2.304 -0,4.7 -0,7.095c0,9.985 0.065,19.969 -0.029,29.953c-0.039,4.089 -0.02,7.979 4.767,10.037l-25.731,0c4.327,-2.195 5.153,-5.885 4.609,-10.677Z"
                style={{ fill: "rgb(255,131,7)", fillRule: "nonzero" }}
              />
              <path
                d="M159.544,213.145c-0.441,-0.149 -0.473,-0.538 -0.488,-1.218c3.692,-4.674 9.796,-6.533 14.106,-4.581c3.38,1.53 5.352,6.327 4.393,10.683c-1.098,4.986 -5.387,7.657 -10.512,6.546c-4.617,-1.001 -6.238,-3.899 -5.347,-9.365c0.199,-1.225 1.945,-3.908 -2.152,-2.065m3.321,-1.593c-0.217,-0.079 -0.434,-0.158 -0.651,-0.238c0.068,0.224 0.135,0.448 0.651,0.238Z"
                style={{ fill: "rgb(255,135,15)", fillRule: "nonzero" }}
              />
              <path
                d="M71.416,192.995c-0.425,-0.424 -0.43,-0.795 -0.435,-1.444c7.415,-0.279 14.831,-0.279 22.268,-0.279l0,21.133c-0.378,0.235 -0.757,0.47 -1.136,0.705c-3.844,-9.842 -6.394,-21.085 -20.697,-20.115Z"
                style={{ fill: "rgb(255,137,20)", fillRule: "nonzero" }}
              />
              <path
                d="M254.868,341.275c0.849,1.083 1.651,2.187 1.655,3.294c0.031,10.113 -0.005,20.229 -0.237,30.339c-0.028,1.241 -1.318,2.453 -2.023,3.678c-0.717,-1.192 -2.041,-2.376 -2.055,-3.577c-0.125,-10.112 -0.079,-20.228 0.13,-30.339c0.024,-1.138 1.377,-2.249 2.53,-3.395Z"
                style={{ fill: "rgb(255,101,101)", fillRule: "nonzero" }}
              />
              <path
                d="M207.299,112.599c1.449,3.409 2.748,6.466 4.047,9.523c-0.218,0.456 -0.435,0.912 -0.653,1.367c-1.289,-0.531 -3.331,-0.719 -3.753,-1.646c-4.826,-10.585 -9.405,-21.283 -14.024,-31.891c2.348,-2.45 3.737,-2.048 4.911,0.743c3.036,7.215 6.206,14.372 9.472,21.904Z"
                style={{ fill: "rgb(255,100,100)", fillRule: "nonzero" }}
              />
              <path
                d="M310.256,359.857c-3.84,-8.836 -7.525,-17.325 -11.324,-26.076c1.281,-0.54 2.308,-0.973 3.783,-1.595c4.917,11.268 9.799,22.453 14.665,33.603c-3.286,2.497 -3.31,2.509 -4.777,-0.58c-0.781,-1.645 -1.464,-3.335 -2.347,-5.352Z"
                style={{ fill: "rgb(255,100,100)", fillRule: "nonzero" }}
              />
              <path
                d="M193.352,360.259c4.393,-9.729 8.626,-19.114 13.019,-28.853c1.266,0.464 2.392,0.877 3.855,1.414c-4.886,10.882 -9.572,21.504 -14.502,32.011c-0.489,1.041 -2.468,1.384 -3.756,2.051c-0.278,-0.439 -0.557,-0.878 -0.835,-1.318c0.686,-1.653 1.372,-3.306 2.219,-5.305Z"
                style={{ fill: "rgb(255,100,100)", fillRule: "nonzero" }}
              />
              <path
                d="M311.306,108.429c-2.064,4.588 -3.809,8.919 -5.988,13.018c-0.628,1.181 -2.508,1.697 -3.814,2.518c-0.375,-0.47 -0.751,-0.94 -1.127,-1.41c4.782,-10.564 9.522,-21.148 14.427,-31.655c0.374,-0.801 1.921,-1.054 2.923,-1.562c0.217,1.091 0.923,2.384 0.571,3.241c-2.146,5.223 -4.532,10.347 -6.992,15.85Z"
                style={{ fill: "rgb(255,98,98)", fillRule: "nonzero" }}
              />
              <path
                d="M347.42,312.548c7.445,7.111 14.635,13.976 21.824,20.841c-0.074,0.486 -0.147,0.972 -0.221,1.459c-1.353,-0 -3.244,0.585 -3.977,-0.089c-8.773,-8.075 -17.375,-16.336 -26.287,-24.801c4.705,-4.233 5.885,1.242 8.661,2.59Z"
                style={{ fill: "rgb(255,98,98)", fillRule: "nonzero" }}
              />
              <path
                d="M363.561,130.546c-6.943,6.44 -13.629,12.635 -20.619,19.111c-0.943,-0.811 -1.843,-1.584 -2.808,-2.415c0.412,-0.611 0.615,-1.078 0.956,-1.396c8.145,-7.558 16.334,-15.071 24.441,-22.67c1.88,-1.761 3.418,-2.795 4.971,0.752c-2.162,2.061 -4.423,4.217 -6.941,6.618Z"
                style={{ fill: "rgb(255,100,100)", fillRule: "nonzero" }}
              />
              <path
                d="M150.956,132.041c-3.336,-3.47 -6.415,-6.697 -10.664,-11.15c2.437,0.148 4.283,-0.313 5.052,0.395c8.805,8.104 17.447,16.387 26.113,24.603c-1.235,2.848 -2.408,3.453 -4.685,1.185c-5.068,-5.048 -10.359,-9.874 -15.816,-15.033Z"
                style={{ fill: "rgb(255,101,101)", fillRule: "nonzero" }}
              />
              <path
                d="M160.946,317.939c-5.645,5.197 -10.96,10.233 -16.477,15.037c-0.948,0.825 -2.757,0.662 -4.169,0.955c-0.228,-0.593 -0.457,-1.186 -0.686,-1.779c8.631,-7.972 17.207,-16.005 25.97,-23.829c0.968,-0.865 3,-0.539 4.539,-0.766c0.158,0.445 0.316,0.89 0.474,1.334c-3.13,2.937 -6.26,5.873 -9.651,9.048Z"
                style={{ fill: "rgb(255,98,98)", fillRule: "nonzero" }}
              />
              <path
                d="M253.884,124.102c0.753,-1.992 1.504,-3.525 2.256,-5.057c0.605,1.507 1.71,3.005 1.736,4.522c0.161,9.464 0.081,18.931 0.081,28.312c-3.745,1.141 -4.487,-0.375 -4.409,-3.332c0.213,-7.992 0.236,-15.989 0.336,-24.445Z"
                style={{ fill: "rgb(255,98,98)", fillRule: "nonzero" }}
              />
              <path
                d="M254.865,337.533c-1.121,-1.16 -2.478,-2.409 -2.502,-3.685c-0.154,-7.968 -0.076,-15.944 0.126,-23.912c0.023,-0.924 1.284,-1.818 1.971,-2.726c0.785,0.809 2.24,1.609 2.251,2.428c0.113,8.633 0.026,17.271 -0.117,25.905c-0.011,0.637 -0.88,1.26 -1.729,1.99Z"
                style={{ fill: "rgb(255,110,110)", fillRule: "nonzero" }}
              />
              <path
                d="M258.132,81.338c-0.04,10.397 -0.124,20.338 -0.086,30.278c0.01,2.682 -0.569,4.213 -3.954,2.911c0,-11.479 -0.036,-23.04 0.076,-34.6c0.008,-0.825 1.095,-1.64 1.68,-2.46c0.762,1.138 1.524,2.276 2.284,3.871Z"
                style={{ fill: "rgb(255,98,98)", fillRule: "nonzero" }}
              />
              <path
                d="M221.829,146.063c1.227,2.909 2.303,5.467 3.379,8.024c-0.364,0.398 -0.727,0.795 -1.091,1.193c-1.339,-0.908 -3.321,-1.514 -3.912,-2.773c-3.382,-7.198 -6.509,-14.521 -9.506,-21.89c-0.397,-0.977 0.44,-2.456 0.705,-3.702c1.038,0.797 2.537,1.375 3.036,2.427c2.555,5.389 4.852,10.901 7.389,16.721Z"
                style={{ fill: "rgb(255,101,101)", fillRule: "nonzero" }}
              />
              <path
                d="M295.127,144.27c-1.305,2.929 -2.236,5.648 -3.711,8.031c-0.824,1.331 -2.55,2.103 -3.87,3.128c-0.459,-0.402 -0.917,-0.803 -1.376,-1.205c3.913,-8.686 7.782,-17.393 11.829,-26.016c0.293,-0.623 1.886,-0.635 2.875,-0.931c0.188,1.002 0.818,2.187 0.492,2.976c-1.906,4.607 -4.037,9.121 -6.239,14.017Z"
                style={{ fill: "rgb(255,101,101)", fillRule: "nonzero" }}
              />
              <path
                d="M343.088,271.825c-3.894,-0.614 -7.984,-0.699 -11.613,-2.034c-2.262,-0.833 -5.368,-3.593 -5.321,-5.417c0.059,-2.295 2.657,-4.855 4.724,-6.652c1.371,-1.192 3.732,-1.247 6.133,-1.952c-3.25,10.025 -2.584,11.628 5.617,14.692c0.403,0.8 0.432,1.081 0.46,1.363Z"
                style={{ fill: "rgb(255,135,15)", fillRule: "nonzero" }}
              />
              <path
                d="M363.995,210.761c-0.735,0.077 -1.185,-0.037 -1.777,-0.343c2.659,-3.791 6.399,-5.815 10.746,-4.34c1.803,0.611 3.732,3.403 3.941,5.379c0.177,1.68 -1.668,4.238 -3.321,5.203c-1.022,0.596 -4.069,-0.577 -4.926,-1.831c-1.064,-1.556 -0.804,-4.017 -1.187,-6.561c-1.011,0.729 -2.102,1.516 -3.476,2.493Z"
                style={{ fill: "rgb(255,135,15)", fillRule: "nonzero" }}
              />
              <path
                d="M118.107,191.979c2.621,2.111 4.781,4.865 1.984,7.731c-1.595,1.635 -4.855,2.448 -7.276,2.287c-3.704,-0.248 -5.824,-2.904 -5.308,-6.848c0.187,-1.432 1.045,-2.777 2.288,-4.081c2.726,2.144 4.762,4.208 7.233,6.714c0.541,-2.909 0.81,-4.356 1.079,-5.803Z"
                style={{ fill: "rgb(247,208,134)", fillRule: "nonzero" }}
              />
              <path
                d="M292.977,320.142c-1.823,-4.276 -3.503,-8.199 -5.183,-12.123c0.417,-0.403 0.834,-0.807 1.251,-1.21c1.36,1.08 3.277,1.882 3.979,3.29c2.516,5.047 4.72,10.261 6.796,15.509c0.41,1.038 -0.269,2.507 -0.445,3.777c-1.095,-0.677 -2.609,-1.103 -3.198,-2.084c-1.271,-2.118 -2.066,-4.521 -3.2,-7.159Z"
                style={{ fill: "rgb(255,101,101)", fillRule: "nonzero" }}
              />
              <path
                d="M213.868,314.729c2.183,-2.888 1.313,-8.522 7.75,-7.135c-3.009,6.661 -5.792,13.016 -8.807,19.258c-0.398,0.824 -1.977,1.078 -3.01,1.596c-0.207,-1.128 -0.887,-2.436 -0.537,-3.351c1.303,-3.409 2.943,-6.689 4.604,-10.368Z"
                style={{ fill: "rgb(255,101,101)", fillRule: "nonzero" }}
              />
              <path
                d="M118.053,191.567c-0.215,1.859 -0.484,3.306 -1.025,6.215c-2.471,-2.506 -4.507,-4.57 -6.889,-6.734c1.132,-4.073 2.611,-8.046 4.09,-12.019c0.378,-0.051 0.757,-0.101 1.135,-0.152c0.878,4.093 1.757,8.186 2.689,12.69Z"
                style={{ fill: "rgb(242,87,25)", fillRule: "nonzero" }}
              />
              <path
                d="M310.887,199.875c-2.676,3.332 -6.264,3.557 -9.16,1.202c-1.589,-1.292 -1.646,-4.469 -1.781,-6.805c2.168,0.837 3.722,1.684 5.568,2.614c0.692,0.103 1.091,0.122 1.808,0.217c1.401,0.975 2.483,1.873 3.565,2.772Z"
                style={{ fill: "rgb(250,226,170)", fillRule: "nonzero" }}
              />
              <path
                d="M302.8,190.913c0.672,-2.814 1.344,-5.629 2.016,-8.444c0.484,-0.011 0.968,-0.022 1.452,-0.034c0.275,1.032 0.55,2.064 0.787,3.756c-1.123,2.77 -2.21,4.879 -3.481,6.737c-0.381,-0.839 -0.578,-1.427 -0.774,-2.015Z"
                style={{ fill: "rgb(242,87,25)", fillRule: "nonzero" }}
              />
              <path
                d="M303.759,193.178c1.086,-2.108 2.173,-4.217 3.35,-6.637c0.678,1.385 1.265,3.082 1.391,5.214c-0.806,2.048 -1.151,3.66 -1.496,5.272c-0.399,-0.019 -0.798,-0.038 -1.572,-0.457c-0.807,-1.397 -1.24,-2.395 -1.673,-3.392Z"
                style={{ fill: "rgb(255,135,15)", fillRule: "nonzero" }}
              />
              <path
                d="M307.322,197.103c0.027,-1.688 0.372,-3.3 1.063,-5.004c0.446,0.327 0.547,0.746 0.602,1.808c0.551,1.455 1.149,2.268 1.746,3.08c0.116,0.813 0.232,1.625 0.251,2.663c-1.179,-0.674 -2.261,-1.572 -3.662,-2.547Z"
                style={{ fill: "rgb(255,137,20)", fillRule: "nonzero" }}
              />
              <path
                d="M343.451,271.943c-0.391,-0.4 -0.42,-0.681 -0.474,-1.386c2.373,-0.471 4.772,-0.518 7.618,-0.509c0.455,0.422 0.463,0.789 0.48,1.431c-2.414,0.378 -4.837,0.481 -7.624,0.464Z"
                style={{ fill: "rgb(255,164,76)", fillRule: "nonzero" }}
              />
              <path
                d="M303.574,192.928c0.618,1.247 1.051,2.245 1.566,3.558c-1.472,-0.53 -3.026,-1.377 -4.895,-2.36c0.457,-1.068 1.228,-2 2.277,-3.072c0.474,0.447 0.671,1.035 1.052,1.874Z"
                style={{ fill: "rgb(248,200,96)", fillRule: "nonzero" }}
              />
              <path
                d="M311.006,196.904c-0.87,-0.729 -1.468,-1.542 -1.991,-2.671c0.804,0.652 1.535,1.62 1.991,2.671Z"
                style={{ fill: "rgb(247,208,134)", fillRule: "nonzero" }}
              />
              <path
                d="M46.642,191.02c0.363,0.514 0.359,0.936 0.355,1.675c-1.199,0.119 -2.396,-0.079 -3.594,-0.278c-0.015,-0.322 -0.03,-0.645 -0.044,-0.967c0.972,-0.174 1.944,-0.348 3.283,-0.43Z"
                style={{ fill: "rgb(255,137,20)", fillRule: "nonzero" }}
              />
              <path
                d="M162.641,211.769c-0.292,-0.007 -0.359,-0.231 -0.427,-0.455c0.217,0.08 0.434,0.159 0.427,0.455Z"
                style={{ fill: "rgb(255,255,254)", fillRule: "nonzero" }}
              />
            </g>
          </svg>
        </div>
        <div className="main box">
          <div className="main level secondary" id="about">
            <div className="contents">
              <h1>Providing Insights into Fires</h1>
              <br></br>
              <p className="short-p">
                At FireSight we work with clients whose values align with ours,
                people who care deeply about the environment and appreciate that
                it is only by combining our resources that we can rise to meet
                the many challenges that coincident, compounding, complex fire
                events present for land and fire managers. <br></br>
              </p>
              <div class="feature-cards">
                <div class="feature-card">
                  <h2>Landscape Risk Analysis</h2>
                  <p>
                    Using ensemble, simulation-based bushfire modelling to
                    assess bushfire risk provides exceptional decision support
                    quality to fire and land managers. The ability to
                    objectively and consistently quantify risk reduces cost and
                    risk.
                  </p>
                  <Link className="link" to="/insights">
                    <button>More About Landscape Risk Analysis</button>
                  </Link>
                </div>
                <div class="feature-card">
                  <h2>Data Science</h2>
                  <p>
                    Model outputs are only as good as the data inputs. At
                    FireSight we pride ourselves on our superior data, the type
                    that can only be developed and delivered through years of
                    experience in bushfire analysis. <br></br>
                  </p>
                  <Link className="link" to="/datascience">
                    <button>More about Data Science</button>
                  </Link>
                </div>
                <div class="feature-card">
                  <h2>Bespoke reporting</h2>
                  <p>
                    The collective expertise of our team empowers us to
                    customise reports precisely to meet our clients’ needs. The
                    combination of Martyn's PhD research, Dirk's advancing
                    knowledge in fire science, and my evolving understanding of
                    our clients' challenges, our capability to create intuitive
                    and valuable analytics is rapidly expanding. A recent
                    achievement exemplifies our progress: we've successfully
                    developed the capability to calculate avoided loss, allowing
                    customers to quantify the cost-benefit ratio of their
                    mitigation investments.
                  </p>
                  <Link className="link" to="/reporting">
                    <button>More about Bespoke reporting</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="main level fourth">
            <div className="contents">
              <h1 className="big">Our Partners and Customers</h1>

              <div className="partners-container">

                <div>
                  <img alt="City of Gold Coast" src={imgGC}></img>
                </div>
                <div>
                  <img alt="City of Logan" src={imgCOL}></img>
                </div>
                <div>
                  <img alt="Brisbane City Council" src={imgBCC}></img>
                </div>
                
              </div>

              <div className="partners-container">
              <div>
                  <img alt="City of Onkaparinga" src={imgCOO}></img>
                </div>
                <div>
                  <img alt="Wollongong City Council" src={imgWCC}></img>
                </div>
                <div>
                  <img alt="City of Noosa" src={imgNoosa}></img>
                </div>
              </div>

              <div className="partners-container">

                <div>
                  <img alt="Bushfires NT" src={imgBushfireNT}></img>
                </div>
                <div>
                  <img
                    alt="QYAC"
                    src={imgQYAC}
                    style={{
                      width: "auto",
                    }}
                  ></img>
                  <span
                    style={{
                      color: "var(--primary)",
                      fontFamily: "Roboto, sans-serif",
                    }}
                  >
                    Quandamooka Yoolooburrabee Aboriginal Corporation
                  </span>
                </div>
                <div style={{
                  margin:0,
                }}>
                  <img style={{
                  maxWidth:"300px",
                }}
                alt="Queensland Parks and Wildlife Service" src={imgQPWS}></img>
                </div>
              </div>
              <div className="partners-container">
                <div>
                  <img alt="TLT" src={imgTLTGreen}></img>
                </div>
                <div style={{
                  padding:0,
                }}>
                  <img style={{
                  minWidth:"300px",
                }}
                alt="NEBO" src={imgNEBO}></img>
                </div>
                <div style={{
                  margin:0,
                }}>
                  <img style={{
                  maxWidth:"300px",
                }}
                alt="MGCA" src={imgMGCA}></img>
                </div>

              </div>

            </div>
          </div>

          <div className="main level fourth">
            <div className="contents">
              <h1 className="big">Our Team</h1>
              <div className="team-container">
                <div>
                  <img alt="Andrew" src={imgAndrew}></img>
                  <h1>Andrew Sturgess </h1>
                  <h2>Director</h2>
                  <a href="https://www.linkedin.com/in/andrew-sturgess-40a38bb8/">
                    <svg viewBox="0 0 448 512">
                      <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
                    </svg>
                  </a>
                  <a href="mailto: abc@example.com">
                    <svg viewBox="0 0 448 512">
                      <path d="M384 32H64C28.63 32 0 60.63 0 96v320c0 35.38 28.62 64 64 64h320c35.38 0 64-28.62 64-64V96C448 60.63 419.4 32 384 32zM384 336c0 17.67-14.33 32-32 32H96c-17.67 0-32-14.33-32-32V225.9l138.5 69.27C209.3 298.5 216.6 300.2 224 300.2s14.75-1.688 21.47-5.047L384 225.9V336zM384 190.1l-152.8 76.42c-4.5 2.25-9.812 2.25-14.31 0L64 190.1V176c0-17.67 14.33-32 32-32h256c17.67 0 32 14.33 32 32V190.1z" />
                    </svg>
                  </a>
                  <p>
                    Bringing a career in the fire industry spanning more than
                    three decades, I am dedicated to using my years of
                    frontline, academic, in-field experience and the latest
                    innovations in technology and science to connect this with
                    operations for the development of a world class planning and
                    prevention capability.<br></br>
                    <br></br> I lead a specialist team at FireSight, where we
                    are continuing to pioneer a world-class specialist bushfire
                    decision support for asset owners, fire and land managers to
                    mitigate the negative impacts of bushfires. <br></br>
                    <br></br>I am a highly accomplished Fire Behaviour
                    Specialist, having established and led the Predictive
                    Services Capability in the Queensland Fire and Emergency
                    Services. I have a wealth of experience in emergency
                    management where I have demonstrated expertise in the
                    development and delivery of bushfire predictive analytics
                    across the four phases of Disaster Management, Prevention,
                    Preparedness, Response and Recovery. This includes during
                    the most extreme bushfire events in Queensland’s history in
                    the 2018 and 2019 fire seasons where strategic, operational,
                    and tactical advice was provided to frontline firefighters,
                    incident managers, local governments, and the State Disaster
                    Management Committee.
                    <br></br>
                    <br></br>
                    These skills and ability to clearly communicate risk in
                    these high consequence, time critical situations with
                    limited information and resources in complex and uncertain
                    environments are rare. They are equally valuable to fire
                    managers that often only have subjective static risk
                    assessment methodologies to inform their strategic
                    investment decisions.
                  </p>
                </div>
                <div>
                  <img alt="Martyn" src={imgMartyn}></img>
                  <h1>Dr Martyn Eliott</h1>
                  <h2>Bushfire Risk Analyst </h2>
                  <a href="https://www.linkedin.com/in/martyn-eliott-77335015a/">
                    <svg viewBox="0 0 448 512">
                      <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
                    </svg>
                  </a>
                  <a href="mailto: martyn@firesight.co">
                    <svg viewBox="0 0 448 512">
                      <path d="M384 32H64C28.63 32 0 60.63 0 96v320c0 35.38 28.62 64 64 64h320c35.38 0 64-28.62 64-64V96C448 60.63 419.4 32 384 32zM384 336c0 17.67-14.33 32-32 32H96c-17.67 0-32-14.33-32-32V225.9l138.5 69.27C209.3 298.5 216.6 300.2 224 300.2s14.75-1.688 21.47-5.047L384 225.9V336zM384 190.1l-152.8 76.42c-4.5 2.25-9.812 2.25-14.31 0L64 190.1V176c0-17.67 14.33-32 32-32h256c17.67 0 32 14.33 32 32V190.1z" />
                    </svg>
                  </a>
                  <p>
                    Congratulations to Doc Martyn on successfully completing his
                    PhD last December, titled “Economic evaluation of prescribed
                    fire as a wildfire risk mitigation tool”. It was quite the
                    journey, and it has been an absolute pleasure being part of
                    it. Having studied and worked at the University of the
                    Sunshine Coast (USC) Martyn is very well connected with the
                    fire science community in academia.
                    <br></br> <br></br>
                    Martyn has extensive experience with bushfire simulation,
                    including the economics of bushfire management, is
                    innovative and focused on using his skills to solve
                    customer’s problems.
                    <br></br> <br></br>
                    He has years of practise developing and delivering bushfire
                    professional services projects where he has applied fire
                    behaviour analysis to turn data into actionable insights,
                    recommendations and operational outcomes. <br></br>{" "}
                    <br></br>
                    Martyn has honed his simulation and geospatial skills
                    working as a bushfire risk analyst while completing his PhD
                    for the past several years. Martyn’s geospatial skills are
                    second to none and he has tutored many students at the USC
                    in this topic. Some of the papers Martyn has published
                    include, ‘A prescribed fire cost model for public lands in
                    south-east Queensland’, Forest policy and Economics,
                    ‘Planned and unplanned fire regimes on public land in
                    south-east Queensland’, International Journal of Wildland
                    Fire, ‘The response of Cerambycid beetles to long-term fire
                    frequency regimes in subtropical eucalypt forest’, Austral
                    Ecology, and ‘Effects of fire on vegetation and arthropods
                    in a coastal heath, south-east Queensland’. Ecological
                    Management and Restoration
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="level image" id="contact">
        <div className="img-bg-rel"></div>
        <div className="contents mbl-padding">
          <h1>Contact Us</h1>
          <p className=""></p>
          <div className="contact-links">
            <a href="mailto: andrew@firesight.co">
              <svg viewBox="0 0 448 512">
                <path d="M384 32H64C28.63 32 0 60.63 0 96v320c0 35.38 28.62 64 64 64h320c35.38 0 64-28.62 64-64V96C448 60.63 419.4 32 384 32zM384 336c0 17.67-14.33 32-32 32H96c-17.67 0-32-14.33-32-32V225.9l138.5 69.27C209.3 298.5 216.6 300.2 224 300.2s14.75-1.688 21.47-5.047L384 225.9V336zM384 190.1l-152.8 76.42c-4.5 2.25-9.812 2.25-14.31 0L64 190.1V176c0-17.67 14.33-32 32-32h256c17.67 0 32 14.33 32 32V190.1z" />
              </svg>
              <span>Andrew@firesight.co</span>
            </a>
            <a href="https://www.linkedin.com/in/andrew-sturgess-40a38bb8/">
              <svg viewBox="0 0 448 512">
                <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
              </svg>

              <span>Andrew Sturgess</span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
